@media only screen and (min-width: 1024px) {
    .footer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
        width: 100%;
        height: 475px;
        color: black;
        padding: 20px;
        background-color: rgb(247, 247, 247);
        
    }
    
    .footer--header {
        font-size: 18px;
        font-weight: bold;
        text-indent: 20px;
        
    }

    ul.footer--ul {
        list-style: none; 
    }
    
    li.footer--li{
        margin: 20px;
    }
    
    a.footer--a {
        color: black;
        text-decoration: none;
        font-size: 14px;
    }
    
    a.footer--a:hover {
        text-decoration: underline;
        
    }
    
    .footer--bottom {
        display: flex;
        height: 100px;
        align-items: center;
        background-color:  rgb(247, 247, 247);
    }
    
    .footer--bottom-a {
        margin-left: 30px;
        text-decoration: none;
        color: black;
        font-size: 12px;
    }
    
    .footer--bottom-a:hover {
        text-decoration: underline;
    }
    
    .footer--ul--icons {
        display: inline-flex;
        list-style: none;
        margin-top: 20px;
        padding: -50px;
    }
}


@media only screen and (min-width: 320px) {

.footer {
    display: none;
}

.footer--bottom {
    display: none;
}
}

@media only screen and (min-width: 1024px) {

}


