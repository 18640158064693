/* Fade in. This set of features controls the cards on the items page */

.card-detail {
    background: 
      linear-gradient(to right, rgba(100, 200, 200, 1), rgba(100, 200, 200, 1)),
      linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 180, 1), rgba(0, 100, 200, 1));
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
  }
  
  .card-detail:hover,
  .card-detail:focus {
    background-size: 0 0.2em, 100% 0.00em;
  }