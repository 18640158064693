@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');





@media only screen and (min-width: 320px) {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  /* sources https://unsplash.com/photos/c9OCWLka764 */
  body {
    font-family: 'Poppins', sans-serif;
    background-color: white;
    width: 100%;
  }
  
  
  
  .container {
    width: 100%;
    margin: 1px auto;
    overflow: auto;
    height: 100%;
    padding: 1px;
    border-radius: 5px;
    background-color: transparent;
  }
  
 
}


@media only screen and (min-width: 1024px) {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  /* sources https://unsplash.com/photos/c9OCWLka764 */
  body {
    font-family: 'Poppins', sans-serif;
    background-color: white;
  }
  
  
  
  .container {
    width: 100%;
    margin: 1px auto;
    overflow: auto;
    height: 100%;
    padding: 1px;
    border-radius: 5px;
    background-color: transparent;
  }
  
 
}