.login--container {
    display: grid;
    width: 100%;
    height: 700px;
    margin-top: 100px;
    align-items: start;
    justify-content: center;
    
}

.login--container--child {
    display:flex;
    flex-direction: column;
    width: 330px;
    height: 300px;
    
}


.login--header {
    font-size: 22px;
    font-weight: 200;
}

.login--text {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 300;

}

.login--button {
    background-color: black;
    color: white;
    height: 44px;
    border: none;
}

.login--button:hover {
    cursor: pointer;
}

.CA--label {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
}

form {
    display: flex;
    flex-direction: column;
}

input {
    height: 40px;
    border: black 1px solid;
}

input:focus {
    border-bottom: black 3px;
    outline: none;
    border-bottom: black 4px solid;
    padding: 10px;
}


