.line {
    width: 90%;
    border: gray .5px solid;
    margin: auto;
    opacity:  50%;
}

.header--signin-createacc{
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin-top: 60px;
    width: 100%;
    height: 100px;
    border: white 2px solid;
}

.header--signin-createacc-content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.header--signin-createacc-button { 
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    
}

.header--signin-createacc-button-item {
    background-color: black;
    color: white;
    border: grey .5px solid;
}

.header--signin-createacc-button-item:hover {
    box-shadow: 1px 1px 1px 1px #8888;
}


