@media only screen and (min-width: 375px) {
    
    .navbar--main {
        display: none;
    }

    .navbar {
        display: none;
    }
    
    
}


@media only screen and (min-width: 768px) {
    .navbar--main {
        display: none;
    }

    .navbar {
        display: none;
    }
    
    
}





@media only screen and (min-width: 1024px) {



    .navbar--main {
        display: flex;
        width: 100%;
        height: 100px;
        border: white 2px solid;
    }
    
    .navbar--main:nth-of-type(2) {
        justify-items: center;
    }
    
    .navbar--main-div-search {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
    
    
    .navbar--main-search {
        width: 650px;
        height: 45px;
        border: grey 2px solid;
    }
    
    .navbar--main-header {
        display: flex;
        margin-left: 20px;
        align-items: center;
        
    }
    
    .navbar--main-signin-shoppingbag {
        display: flex;
        width: 200px;
        border: white 2px solid;
    }
    
    .navbar--main-signin-item {
        display: flex;
        align-items: center;
        color: black;
    }
    
    .navbar--main-shoppingbag-item {
        display: flex;
        flex: .5;
        align-items: center;
        justify-content: center;
    }
    
    
    
    .navbar {
        display: flex;
        width: 100%;
        margin-top: 20px;
        justify-content: center;
    }
    
    
    ul.navbar--ul {
        display: flex;  
    }
    
    li.navbar-li-items{
        list-style: none;
        font-size: 16px;
        margin-left: 65px;
    }
    
    a.navbar--a-items {
        text-decoration: none;
        color: black;
        font-weight: 100;
        font-size: 16   px;
    }
    
    a.navbar--a-items:hover {
        text-decoration: underline;
        text-decoration-thickness: 15%;
        text-underline-offset: 5px;
    }
    
    
    .navbar--main-header-style {
        text-decoration: none;
        font-size: 40px;
        font-weight: lighter;
        color: black;
    }
    
    .signin--button {
        color: black;
        text-decoration: 1px underline;
        text-underline-offset: 5px;
        margin-left: 10px;
    }
    
    .signin--button:hover {
        text-decoration: none;
    }

 
    
}


