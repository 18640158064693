.MuiGrid-root {

    box-shadow: none;
}

.MuiGrid-container {
    
    box-shadow: none;
}

.MuiGrid-item {

    box-shadow: none;
}

